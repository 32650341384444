import request from './request'

// 获取打卡列表
export const gsfAttendance = (data) => {
  return request.post('/rame/gsfms/gsf_attendance', data)
}
// 6.11.2 打卡审批
export const approval = (data) => {
  return request.post('/rame/gsfms/gsf_attendance/approval', data)
}
